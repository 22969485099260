.info-alert-container {
  background-color: #77a8f6 !important;
  color: white !important;
  padding: 14px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 12px 16px 0px rgba(76, 103, 100, 0.05) !important;
  margin-top: 6px;
  margin-bottom: 8px;
}

.info-text-container {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
}

.info-action-container {
  display: flex;
  justify-content: center;
}

.info-upgrade-button {
  text-transform: none !important;
  color: white !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
}

.info-outlined-icon,
.info-action-container div {
  display: flex;
}

.info-close-button {
  font-size: 20px !important;
  color: white !important;
}

@media (max-width: 768px) {
  .info-outlined-icon {
    display: none;
  }

  .info-alert-container {
    padding: 16px 18px 16px;
  }

  .info-close-button {
    position: absolute;
    top: -8px;
    right: -8px;
  }

  .info-action-container {
    height: 'auto';
    width: 'auto';
  }

  .info-close-button-wrapper {
    padding: 0 !important;
    width: 'auto';
    height: 'auto';
    min-width: 0;
    min-height: 0;
  }
}
