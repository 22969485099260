.authorized-signers-text {
  margin-top: 16px;
  color: gray;
  font-size: 16px;
}

.title-question-container {
  margin-top: 16px;
  color: gray;
  font-size: 16px;
  display: flex;
  gap: 85px;
}

.title-question-header {
  font-weight: 600;
  color: #525256 !important;
}

.title-question-body {
  display: flex;
  justify-content: center;
}

.checkbox-container-title {
  display: flex;
  justify-content: center;
}

.checkbox-container-title {
  align-items: center;
}

.authorized-signer-email-container {
  display: flex;
  gap: 24px;
}

.as-form-field-container {
  margin-bottom: 4px;
  margin-top: 16px;
  width: 33%;
}

.authorized-signer-email-header {
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 8px;
  color: #525256;
  font-weight: 600;
  margin-top: 24px;
}

.authorized-signer-title-container {
  display: flex;
  justify-content: center;
}
