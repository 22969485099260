.warning-alert-container {
  background-color: #ffd965 !important;
  color: black !important;
  padding: 14px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 12px 16px 0px rgba(76, 103, 100, 0.05) !important;
}

.warning-alert-text-container {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
}

.warning-alert-action-container {
  display: flex;
}

.warning-alert-action-button {
  text-transform: none !important;
  color: black !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
}

.warning-alert-text-container div,
.warning-alert-action-container div {
  display: flex;
}

.warning-alert-close-button {
  font-size: 20px !important;
  color: black !important;
}
