html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: #f6f7f9 !important;
}

.MuiCard-root {
  border-radius: 16px !important;
  background: var(--white, #fdfdfd) !important;
  box-shadow: 0px 12px 16px 0px rgba(76, 103, 100, 0.05) !important;
}

.MuiStepIcon-text {
  fill: #9f9f9f !important;
}

/* .compliance-one-stepper .MuiStepLabel-iconContainer circle {
  color:white!important;
  box-shadow: 0px 12px 16px 0px rgba(76, 103, 100, 0.05)!important;
  width: 40px!important;
  height: 40px!important;
  flex-shrink: 0!important;
}

.compliance-one-stepper {
  margin-bottom: 12px;
} */

/* .css-1eluldt-MuiContainer-root {
  max-width: 535px !important;
}

.css-1nlx512-MuiTypography-root {
  margin-top: 20px !important;
} */

.MuiChip-root {
  border-radius: 4px !important;
}

.duties-fees-button {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.duties-fees-button Button {
  font-weight: bold;
  font-size: 20px !important;
  width: 300px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 30px;
}

.transportation-buttons Button {
  font-weight: bold;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
  text-transform: none;
  margin-bottom: 30px;
  border-radius: 30px;
}

.pricing-buttons Button {
  font-weight: bold;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  height: 60px;
  width: 260px;
  text-transform: none;
  border-radius: 30px;
  margin-bottom: 30px;
}

.icon-class {
  font-size: 80px !important;
  height: 105px;
}

.transportation-buttons {
  display: flex;
  justify-content: center;
}

.payment-buttons,
.payment-review,
.upload-docs {
  display: flex;
  justify-content: center;
}

.payment-buttons Button {
  font-weight: bold;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
  height: 60px;
  width: 280px;
  text-transform: none;
  border-radius: 30px;
  margin-bottom: 30px;
}

.payment-review Button {
  font-weight: bold;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
  height: 60px;
  width: 280px;
  text-transform: none;
  border-radius: 30px;
  margin-bottom: 30px;
}

.upload-docs label {
  font-weight: bold;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  height: 60px;
  width: 230px;
  margin-top: 5px;
  text-transform: none;
  border-radius: 30px;
}

.upload-docs Button {
  font-weight: bold;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  height: 60px;
  width: 260px;
  text-transform: none;
  border-radius: 30px;
}

.upload-product-images Button {
  font-weight: bold !important;
  font-size: 20px !important;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
  height: 60px;
  width: 260px;
  text-transform: none;
  border-radius: 30px;
  margin-bottom: 30px;
}

.card-subtitle {
  display: flex;
  justify-content: center;
}

.card-subtitle-text {
  width: 70%;
  text-align: center;
}

.text-container {
  display: flex;
  justify-content: center;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 20px;
}

/* .css-1flc6sh-MuiSvgIcon-root {
  margin-right: 0px !important;
} */

.MuiAccordionSummary-content {
  justify-content: space-between;
}

.my-shipments-list .MuiTypography-root {
  display: flex;
  align-items: center;
}

.MuiDialogTitle-root {
  text-align: left;
  color: var(--secondary, #525256) !important;
  /* Bold 24-28 */
  font-family: Inter;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important; /* 116.667% */
  padding: 0px !important;
}

.card-container-signup {
  display: flex;
  justify-content: center;
  margin: 16px;
}

.signup-container .MuiPaper-root {
  padding: 48px;
}

.code-form .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: center;
}

.contact-us-dialog-container .MuiDialog-paper {
  background-color: #388e3c !important;
}

.error-text {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 14px;
  color: red;
}

.universal-subheader {
  color: slategray;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 24px;
}

.subscription-universal-subheader {
  color: slategray;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.settings-universal-subheader {
  color: slategray;
  font-size: 16px;
}

.universal-subheader-two {
  color: #525256;
}

.MuiInputBase-root {
  border-radius: 4px !important;
}

.next-button-container {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

.next-button-container .next-button-green {
  text-transform: none !important;
  background-color: #388e3c !important;
  color: #fdfdfd !important;
  font-size: 16px !important;
  font-style: normal !important;
  width: 120px;
  font-weight: 600 !important;
  line-height: 24px !important;
  display: flex !important;
  padding: 8px 20px !important;
  align-items: center !important;
  gap: 8px !important;
}

.next-button-container .next-button-green:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}

.next-button-container .next-button-green:disabled {
  background-color: #eeeeee !important;
}

.header-question .MuiTypography-root {
  color: #525256 !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}

.header-question-duty-calculator .MuiTypography-root {
  color: #525256 !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}

.header-question-duty-calculator {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.cargo-value-text-field {
  margin-top: 32px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.unlading-descriptive-text {
  font-size: 16px;
  color: #525256;
  justify-content: left;
  margin-top: 16px;
  flex-wrap: wrap;
  display: inline-block;
}

.transportation-type-descriptive-text {
  font-size: 16px;
  color: #525256;
  display: inline-block;
  justify-content: left;
  margin-top: 16px;
  flex-wrap: wrap;
}

.transportation-type-descriptive-text .tooltip-container {
  padding-left: 3px;
}

.bottom-description {
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 11px;
  margin-top: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  color: #525256;
}

.parent-classification {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.hts-code-text-field {
  margin-top: 25px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.MuiCardContent-root {
  padding: 32px !important;
}

.variable-value-text-field {
  margin-top: 15px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: left;
}

.unlading-descriptive-text .tooltip-container {
  padding-left: 3px;
  padding-right: 3px;
}

.transportation-header-container {
  display: flex;
}

.tooltip-container {
  display: inline;
  align-items: center;
  line-height: 1.5;
}

.transportation-header-container .tooltip-container {
  padding-left: 3px;
  display: flex;
  align-items: center;
}

.MuiDialog-paper {
  padding: 32px !important;
}

.MuiDialogContent-root {
  padding: 0px !important;
}

.next-button-container .back-button-text {
  text-transform: none !important;
  font-size: 16px !important;
  font-style: normal !important;
  width: 120px;
  font-weight: 600 !important;
  line-height: 24px !important;
  display: flex !important;
  padding: 8px 20px !important;
  align-items: center !important;
  gap: 8px !important;
  margin-right: 24px;
}

.input-grid-container {
  gap: 16px;
  margin-top: 24px;
}

.phone-auth-button {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.main-page-header .MuiTypography-root {
  font-weight: 600;
}

.menu-button-text-container {
  width: 250px;
  margin-left: 12px;
  display: flex;
  justify-content: left;
}

.MuiButton-startIcon {
  font-size: 24px !important;
}

.next-button-container .next-button-dc {
  text-transform: none !important;
  background-color: #388e3c !important;
  color: #fdfdfd !important;
  font-size: 16px !important;
  font-style: normal !important;
  width: 225px;
  font-weight: 600 !important;
  line-height: 24px !important;
  display: flex !important;
  padding: 8px 20px !important;
  align-items: center !important;
  gap: 8px !important;
}

.next-button-container .next-button-dc:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}

.purchase-plan-container .MuiBox-root {
  border-radius: 16px !important;
}

.planDetailsWrapper .MuiModal-root {
  border-radius: 16px !important;
}

.MuiTablePagination-root:last-child {
  border-bottom: none !important;
}

.compliance-next-button-container {
  display: flex;
  justify-content: right;
}

.next-button-container .clear-compliance-buttons {
  text-transform: none !important;
  font-size: 16px !important;
  font-style: normal !important;
  width: 120px;
  font-weight: 600 !important;
  line-height: 24px !important;
  display: flex !important;
  padding: 16px !important;
  align-items: center !important;
  gap: 8px !important;
}

.compliance-next-button {
  margin-top: 0px !important;
}

.vessel-snackbar-container {
  margin-top: 24px;
  margin-right: 10px;
  margin-left: 10px;
}

.need-help {
  bottom: 0;
  position: fixed;
  right: 0;
  margin-bottom: 25px;
  margin-right: 25px;
}

.hts-example {
  display: flex;
  justify-content: center;
  font-size: 16px;
  text-align: center;
}

.hts-example-title {
  font-size: 16px;
  color: #388e3c;
  text-align: center;
  margin-top: 15px;
  font-weight: 600;
}

.numbers div {
  flex-grow: 1;
  text-align: center;
  padding: 6px;
}

.numbers {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--primary-color, #388e3c);
  background: var(--white, #fdfdfd);
  padding: 16px;
  flex-grow: 1;
}

.hts-classification-buttons-container,
.dont-know-code,
.hts-next {
  display: flex;
  justify-content: center;
}

.hts-classification-buttons-container Button {
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  height: 60px;
  border-radius: 30px;
  text-transform: none;
  margin-bottom: 30px;
  font-weight: bold;
}

.dont-know-code Button {
  font-size: 16px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  border-radius: 30px;
  text-transform: none;
}

.hts-next Button {
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  height: 60px;
  border-radius: 30px;
  text-transform: none;
  margin-bottom: 0px !important;
  margin-top: 10px !important;
  font-weight: bold;
}

.dialog-hts-container {
  margin-left: 5%;
  margin-right: 5%;
}

.hts-textarea-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.tooltip-hover-text {
  margin-right: 3px;
}

.content-step-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-step-content {
  flex-grow: 1;
}

/*
  TODO: we'll make the simple list into a reusable component
    for when the ImportalTable is overkill
*/
.importal-list {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid lightgrey;
  border-radius: 8px;
  overflow-y: scroll;
  max-height: 500px;
}

.importal-list-item {
  display: grid !important;
  grid-template-rows: 1fr;
}

.importal-list > .importal-list-item:nth-child(odd) {
  background-color: #efefef;
}

@media (max-width: 480px) {
  .parent-classification {
    width: 100%;
  }
  .input-grid-container {
    gap: 6px;
  }
  .parent-classification {
    margin-left: 4px;
    margin-right: 4px;
  }
  .numbers {
    padding: 0px;
    font-size: 12px;
  }
  .bottom-description {
    font-size: 12px;
  }

  .header-question .MuiTypography-root {
    font-size: 18px !important;
  }

  .legal-text {
    padding: 0px 0px;
    bottom: 0;
  }
}

.MuiDialog-paper {
  border-radius: 16px !important;
}
